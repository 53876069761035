import React from 'react'
import '../sass/Presentation.scss'

const Presentation = () => {
  return (
    <div>
        <div className="presentation-wrapper" id='description'>
            <div className="my-photo">
                <img src={`${process.env.PUBLIC_URL}/img/my-photo.png`} alt="presentation-en-code" />
            </div>
            <div className="aboutme">
                <h2>Développeur Web & Web Mobile</h2>
                <h4>A propos de moi</h4>
                <p>Passionné par le voyage, j'aime partir à l'aventure loin des sentiers battus. Sportif et compétiteur dans l'âme, j'aime travailler en équipe et partager des idées autour d'un projet. Autonome dans le travail comme dans la vie, j'aime m'approprier les projets sur lesquels je travaille.</p>
                <h4>Je cherche</h4>
                <p>Diplômé de la formation Développeur Web & Web Mobile de M2I Formation à Villeneuve d'ascq, j'ai pu acquérir des bases solide dans la programmation et notamment en JavaScript et le framework React JS ainsi que Node JS.</p>
                <p> À la fin de ma formation j'ai eu la chance de faire un stage de 2 mois chez Midas internationale ou j'ai eu l'occasion de travailler sur la refonte du site interne en Angular JS. Malgré le fait que je ne connaissais pas ce framework j'ai su m'adapter et apprendre par moi-même afin de rendre un code propre et pérenne. Je suis à la recherche d'un emploi orienté JavaScript, dans une entreprise dynamique et/ou il est agréable de venir travailler. </p>
            </div>
        </div>
    </div>
  )
}

export default Presentation